import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/Mondaic/infrastructure/website/src/templates/mdx_template.tsx";
import { Embed } from 'semantic-ui-react';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`3D Simulations`}</h1>
    <p>{`Salvus excels in the large-scale computation of fully 3-D wavefields in
realistic and complicated environments. In this use case, Salvus was
used to investigate the character of vibrational strain signals recorded
along an ocean bottom fiber-optic cable. High resolution surface and
seabed topography, along with the accurate coupling of both viscoelastic
and acoustic wavefields, are automatically included using our simple
Python-based interface. Simulations such as these help researchers
investigate new and exciting applications of distributed acoustic
sensing (DAS).
The following illustration highlights a few of the complexities Salvus
handles - click on it to see a video of these waves propagating. All
this readily scales from running on laptops, to GPU clusters all the way
to big machines with thousands of cores. The simulation rendered below
took less than a few minutes to run on a single GPU.`}</p>
    <Embed id="465477132" source="vimeo" autoPlay={false} placeholder="https://vumbnail.com/465477132.jpg" iframe={{
      allowFullScreen: true
    }} mdxType="Embed" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      